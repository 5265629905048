<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" class="pt-0">

          <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'px-5 pt-8' : 'px-5'">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="#d31145"
              :class="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'hidden-arrow' : ''"
              slider-size="4">
              <v-tabs-slider color="#d31145"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in menu"
                :key="index"
                class="text-capitalize">
                <span class="body-2" :class="tab === index ? 'font-weight-bold color-default' : 'text-third'">
                  {{ item.label }}
                </span>
              </v-tab>
            </v-tabs>
          </div>
        <!-- </v-app-bar> -->

        <v-sheet
          class="overflow-y-auto"
          :max-height="$vuetify.breakpoint.name === 'xs' ? 'calc(100vh - 100px)' : 'calc(100vh - 125px)'"
        >
          <v-tabs-items v-model="tab" touchless>
            <v-slide-x-transition>
              <router-view class="my-2" />
            </v-slide-x-transition>
            <v-tab-item> </v-tab-item>
          </v-tabs-items>

        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TokenService } from "@/service/Storage.Service";
export default {
  data() {
    return {
      tab: 0,
      menu_baru: [],
      menu: [
        {
          label: "Ringkasan Penjualan",
          value: "/sales/summary",
        },
        {
          label: "Ringkasan Penjualan Refund",
          value: "/sales/refund",
        },
        {
          label: 'Ringkasan Penjualan Terhapus',
          value: '/sales/void'
        },
        {
          label: "Penjualan Per Kategori",
          value: "/sales/perCategory",
        },
        {
          label: 'Penjualan Per Produk',
          value: '/sales/perProduct'
        },
        {
          label: "Laba Produk",
          value: "/sales/profitProduct",
        },
        {
          label: "Penjualan Per Tipe Pelanggan",
          value: "/sales/perCustomerCategory",
        },
        {
          label: "Penjualan Per Pegawai",
          value: "/sales/perEmployees",
        },
        {
          label: "Penjualan Per Tipe Penjualan",
          value: "/sales/perType",
        },
        {
          label: "Penjualan Per Metode Pembayaran",
          value: "/sales/typePayment",
        },
        {
          label: "Penjualan Harian",
          value: "/sales/perDaily",
        },
        {
          label: "Penjualan Bulanan",
          value: "/sales/perMonthly",
        },
        {
          label: "Penjualan Tahunan",
          value: "/sales/perYearly",
        },
      ],
    };
  },
  components: {},
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
  },
  watch: {
    tab: function(val) {
      if (val >= 0) {
        if (this.$route.path != this.menu[val].value) {
          this.$router.push({ path: `${this.menu[val].value}` });
        }
      }
    },
    $route(to, from) {
      //nothing
    },
  },
  mounted() {
    this.menu_privilage;
    let index = this.menu.findIndex(
      (item) => item.value === this.$route.path
    );
    this.tab = index;
  },
  methods: {},
};
</script>
